h1, h2, h3, h4, h5, h6, div, body, li, ul, p{
  padding:  0;
  margin: 0;
}
body{
  color: #333;
}
.ant-pagination-item{
  border: none;
  line-height: 32px;
  margin-right: 10px;
}
.ant-pagination-prev .ant-pagination-item-link, 
.ant-pagination-next .ant-pagination-item-link{
  border: none;
}
.ant-pagination a{
  color: #1a1a1a;
  font-size: 14px;
  font-family: inherit;
}
.ant-pagination-disabled, .ant-pagination-disabled:hover, .ant-pagination-disabled:focus{
  a{
    color: rgba(0, 0, 0, 0.3);
  }
}
.ant-pagination-item-active a{
  color: #8590a6;
}

.ant-tooltip-inner{
  border-radius: 4px;
  font-size: 12px;
}
.global-spin{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.ant-modal-centered{
  display: flex;
  align-items: center;
}

// 滚动轴
::-webkit-scrollbar {/*滚动条整体样式*/
  width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}

::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #ccc;
}

::-webkit-scrollbar-track {/*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  background: transparent;
}

.ant-pagination-jump-next, .ant-pagination-jump-prev{
  cursor: default;
}

/* Small screens (default) */
html { font-size: 100%; }

/* Medium screens (640px) */
@media (min-width: 40rem) {
  html { font-size: 112%; }
}

/* Large screens (1024px) */
@media (min-width: 64rem) {
  html { font-size: 120%; }
}