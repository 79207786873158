
// 预览图片
.priview-modal{
  width: 100vw !important;
  .ant-modal-content{
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-close-x{
    display: none;
  }
  .ant-modal-body{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .img-wrap{
    position: relative;
    max-width: 750px;
    text-align: center;
    img{
      max-width: 750px;
      max-height: 450px;
    }
    .description{
      color: rgba(255,255,255,0.9);
      margin-top: 32px;
      font-size: 13px;
      text-align: center;
      position: relative;
      z-index: 1000;
      
    }
    .anticon-close{
      position: absolute;
      top: -37px;
      right: -47px;
      font-size: 32px;
      color: #5e5e5e;
      cursor: pointer;
      z-index: 1000;
      &:hover{
        color: rgba(255, 255, 255, .8);
      }
    }
  }
  .preview-handle{
    position: absolute;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    &:hover{
      color: rgba(255, 255, 255, .8);
    }
    &.last-one{
      left: 100px;
    }
    &.next-one{
      right: 100px;
    }
  }
  .half-preview{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50vw;
    cursor: pointer;
    z-index: 99;
    user-select: none;
    &.half-right{
      right: 0;
      &:hover .next-one{
        color: rgba(255, 255, 255, .8);
      }
    }
    &.half-left{
      left: 0;
      &:hover .last-one{
        color: rgba(255, 255, 255, .8);
      }
    }
  }
  .btn-line{
    background-color: rgba(47, 47, 47, 0.9);
    border-radius: 45px;
    padding: 11px 4px;
    line-height: 32px;
    z-index: 1001;
    font-size: 14px;
    opacity: 0;
    // position: absolute;
    // bottom: -150px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translatex(-50%);
    transition: all 0.3s;
    &.show{
      opacity: 1;
    }
    .btn{
      padding: 0 35px;
      line-height: 28px;
      height: 28px;
      color: rgba(255, 255, 255, .8);
      min-width: 60px;
      cursor: pointer;
    }
    .check-origin-img{
      border-right: 1px solid #5C5C5C;
    }
  }
}

@media screen and (max-width: 750px){
  .priview-modal {
    .img-wrap{
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
    .img-wrap .anticon-close{
      right: 0px;
      top: 0px;
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      // display: inline-block;
    }
    .preview-handle{
      display: none;
    }
    .btn-line{
      padding: 4px;
    }
    .btn-line .btn{
      padding: 0 18px;
    }
  }
}
@media screen and (max-width: 750px){
  .priview-modal .btn-line{
    bottom: 60px;
    .download-img{
      display: none;
    }
    .check-origin-img{
      border-right: none;
    }
  }
}
